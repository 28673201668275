import {removeSession} from "./api";
import Vue from 'vue'
import MixIn from "@/common/mixin";
import ApiMixIn from "@/common/api_mixin";

const MyPageMixIn = {
    mixins: [MixIn, ApiMixIn],
    data: function() {
        return {
            userData: null,
            deleteAccountValidationErrors: {}
        }
    },
    created: function () {
        if (Vue.prototype.$user) {
            this.userData = Vue.prototype.$user;
        }
        if (!this.userData) {
            this.getMyData();
        }
    },
    computed: {
        companyData: {
            get() {
                if (!this.userData || !this.userData.userGroup) {
                    return null;
                }
                return this.userData.userGroup.company;
            }
        },
        jobInfoList: {
            get() {
                if (!this.companyData || !this.companyData.jobInfoList) {
                    return [];
                }
                return this.companyData.jobInfoList;
            }
        }
    },
    methods: {
        async getMyData() {
            const response = await this.getApi("user/mydata");
            if (response && response.data && response.data.user) {
                Vue.prototype.$user = response.data.user;
                this.userData = response.data.user;
            }
        },
        async logout () {
            const response = await this.postApi("auth/logout", undefined, true);
            if (response) {
                removeSession();
                Vue.prototype.$user = null;
                this.$router.push({ path: '/' });
            }
        },
        async deleteAccount (mailAddress) {
            const response = await this.postApi("user/delete", {
                mailAddress: mailAddress
            }, true, true);
            if (response) {
                if (response.validationErrors) {
                    this.deleteAccountValidationErrors = response.validationErrors;
                } else {
                    // eslint-disable-next-line no-undef
                    $('#delete-account').modal("hide");
                    removeSession();
                    Vue.prototype.$user = null;
                    this.pushResult("削除完了", "アカウントを削除しました");
                }
            } else {
                // eslint-disable-next-line no-undef
                $('#delete-account').modal("hide");
            }
        }
    }
}

export default MyPageMixIn;
