<template>
  <div>
    <transition name="vload">
      <div v-show="isLoading" class="Loader">
        <div class="sk-cube-grid">
          <div class="sk-cube sk-cube1"></div>
          <div class="sk-cube sk-cube2"></div>
          <div class="sk-cube sk-cube3"></div>
          <div class="sk-cube sk-cube4"></div>
          <div class="sk-cube sk-cube5"></div>
          <div class="sk-cube sk-cube6"></div>
          <div class="sk-cube sk-cube7"></div>
          <div class="sk-cube sk-cube8"></div>
          <div class="sk-cube sk-cube9"></div>
        </div>
        <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
      </div>
    </transition>
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <Header></Header>
      <section class="fixed mypage-contents-fixed">
        <div class="container">
          <div class="row">
            <MyPageContents class="col-lg-3 col-sm-12" v-on:logout="logout" v-on:delete-account="deleteAccount" :user-data="userData" :validation-errors="deleteAccountValidationErrors"></MyPageContents>
            <div v-if="companyData" class="col-lg-9 col-sm-12 mx-auto mypage-right-frame">
              <div class="form-title-group">
                <div class="menu-active-mark" style="margin-top: 10px;"/>
                <div class="form-title"><h1>求人管理</h1></div>
              </div>
              <div class="menu-bar"/>
              <p class="form-text">求人の確認・新規作成・編集・削除を行うことができます。求人は最大3件登録することが可能です。</p>
              <!--一覧がないのでここでデザインを作る-->
              <div class="_large_jb_list72 shadow_0" style="margin-bottom: 15px; border: none">
                <div v-for="jobInfo of jobInfoList" :key="jobInfo.id" class="_large_jb_header" style="border: 1px solid #e5e5e5; margin-bottom: 15px;">
                  <div class="_list_110" style="float: left;">
                    <div class="_list_110_caption">
                      <div class="company-name"><h4 class="_jb_title">{{jobInfo.title}}</h4></div>
                      <!--ここは実装してもらう。求人作成日は情報ないかもしれない。あわよくば更新日もほしい-->
                      <div class="job-under-frame">
                        <div class="_emp_jb job-frame">{{getJobCategoryText(jobInfo.jobCategory)}}</div>
                        <div class="_emp_jb job-date">作成日：{{getDateText(jobInfo.createdAt)}}</div>
                        <div class="_emp_jb job-date">更新日：{{getDateText(jobInfo.updatedAt)}}</div>
                      </div>
                      <!--ここは実装してもらう-->
                    </div>
                  </div>
                  <div class="job-button-group">
                    <button class="form-button job-button" @click="onClickJobInfoDetail(jobInfo)"><p>詳細</p></button>
                    <button class="form-button job-button" @click="toEdit(jobInfo.id)"><p>編集</p></button>
                    <button class="form-button job-button" @click="deleteJobInfo(jobInfo.id)"><p>削除</p></button>
                  </div>
                </div>
              </div>
              <!--ここまで-->
              <router-link v-if="jobInfoList.length < 3" class="btn-md full-width pop-login mypage-menu mypage-menu-under" to="/registerjobinfo">求人追加</router-link>
              </div>
            </div>
          </div>
      </section>
      <Footer></Footer>
    </div>
    <job-info-detail-modal :selected-job-info="selectedJobInfo" :is-show-job-contact="false"></job-info-detail-modal>
  </div>
</template>
<script>
import Header from "@/views/components/Header";
import Footer from "@/views/components/Footer";
import MyPageContents from "@/views/components/MyPageContents";
import MyPageMixIn from "@/common/mypage_mixin";
import Vue from "vue";
import {getJobCategoryText} from "@/common/enum";
import moment from "moment";
import JobInfoDetailModal from "@/views/components/JobInfoDetailModal";

export default {
  name: "ChangeName",
  components: {Footer, Header, MyPageContents, JobInfoDetailModal},
  mixins: [MyPageMixIn],
  data: function() {
    return {
      userName: "",
      isChanged: false,
      selectedJobInfo: null
    }
  },
  watch: {
    userData: function () {
      if (!this.userData) {
        this.userName = "";
      }
      this.userName = this.userData.name;
    }
  },
  methods: {
    async send () {
      const response = await this.postApi("user/changeUserName", {
        userName: this.userName,
      });
      if (response) {
        this.isChanged = true;
        Vue.prototype.$user = response.data.user;
        this.userData = response.data.user;
      } else {
        this.isChanged = false;
      }
    },
    getJobCategoryText (jobCategoryType) {
      return getJobCategoryText(jobCategoryType);
    },
    getDateText(date) {
      return moment(date).format("YYYY年MM月DD日");
    },
    toEdit(id) {
      this.$router.push({ name: 'EditJobInfo', query: { id: id } });
    },
    async deleteJobInfo(id) {
      if (window.confirm("削除してよろしいですか?")) {
        const response = await this.postApi("jobInfo/delete", { id });
        if (response) {
          this.userData = response.data.user;
          Vue.prototype.$user = response.data.user;
          this.pushResult("削除完了", "求人情報を削除しました");
        }
      }
    },
    onClickJobInfoDetail(jobInfo) {
      this.selectedJobInfo = {
        company: this.companyData,
        jobInfo
      };
      // eslint-disable-next-line no-undef
      $('#job-info-detail').modal("show");
    }
  }
}
</script>

<style scoped>

</style>