<template>
    <div class="menu-side-bar">
        <section class="mypage-side-menu" style="position: sticky; top: 0;">
            <div class="container">
                <div class="row">
                  <div class="side-menu-frame mb-2">
                    <div class="mx-auto mypage-menu-contents">
                        <template v-if="companyData">
                            <router-link class="btn-md full-width pop-login mypage-menu sidebar-menu" to="/mypage">企業管理</router-link>
                        </template>
                        <template v-else>
                            <details class="accordion-008">
                                <summary>企業管理</summary>
                                <router-link class="btn-md full-width pop-login mypage-menu sidebar-menu accordion-menu" to="/registercompany">企業登録</router-link>
                                <router-link class="btn-md full-width pop-login mypage-menu sidebar-menu accordion-menu" to="/linklistedcompany">企業紐付け</router-link>
                            </details>
                        </template>
                      <template v-if="companyData">
                        <router-link class="btn-md full-width pop-login mypage-menu sidebar-menu" to="/jobinfo">求人管理</router-link>
                      </template>
                      <!--<details class="accordion-008">
                        <summary>求人管理</summary>
                          <router-link class="btn-md full-width pop-login mypage-menu accordion-menu" style="margin-top: 10px" to="/jobinfo">求人管理</router-link>
                          <router-link class="btn-md full-width pop-login mypage-menu accordion-menu" to="/registerjobinfo">求人確認</router-link>
                          <router-link class="btn-md full-width pop-login mypage-menu accordion-menu" style="margin-bottom: 10px" to="/editjobinfo">求人編集・削除</router-link>
                      </details>-->
                      <details v-if="isOwner" class="accordion-008">
                        <summary>ユーザー管理</summary>
                        <router-link class="btn-md full-width pop-login mypage-menu sidebar-menu accordion-menu" style="margin-top: 10px" to="/userinvitation">ユーザー招待</router-link>
                        <router-link class="btn-md full-width pop-login mypage-menu sidebar-menu accordion-menu" style="margin-bottom: 10px" to="/userexpulsion">ユーザー除籍</router-link>
                      </details>
                      <details class="accordion-008">
                        <summary>アカウント設定</summary>
                        <router-link class="btn-md full-width pop-login mypage-menu sidebar-menu accordion-menu" style="margin-top: 10px" to="/changename">ユーザー名変更</router-link>
                        <router-link class="btn-md full-width pop-login mypage-menu sidebar-menu accordion-menu" to="/changepassword">パスワード変更</router-link>
                        <a class="btn-md full-width pop-login mypage-menu sidebar-menu mypage-menu-not-recommended accordion-menu" style="margin-bottom: 10px" data-toggle="modal" data-target="#delete-account">
                          アカウント削除
                        </a>
                      </details>
                      <a type="submit" class="btn-md full-width pop-login mypage-menu sidebar-menu mypage-menu-not-recommended logout" @click="logout">ログアウト</a>
                    </div>
                  </div>
                </div>
            </div>

        <!--<div class="mx-auto mypage-side-bnr"> 
        <a href="assets/img/user_guide.pdf" download @click="openPage()"><img class="img-fluid" src="assets/img/btn_guide_01@2x.jpg" alt="SES MEDIA ご利用ガイド ダウンロードはこちら" width="255" height="98"></a>
        </div>-->

        </section>
        <div class="modal fade" id="delete-account" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered login-pop-form" role="document">
                <div class="modal-content modal-frame">
                    <div class="modal-header">
                        <h4>アカウント削除</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="ti-close"></i></span></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12 login-form-top-text" style="margin: 0">
                            <label>アカウントを削除する場合、確認のため削除対象アカウントのメールアドレスを入力して下さい。</label>
                        </div>
                        <div>
                            <div v-if="hasValidationError" class="error-area">
                                <p v-for="item in allValidationError" :key="item.validationText">
                                    ・{{item.validationText}}
                                </p>
                            </div>

                            <form @submit.prevent="deleteAccount">
                                <div class="form-group" style="display:block; margin-bottom: 0px; border: none;">
                                    <label style="padding-left: 5px">メールアドレス</label>
                                    <label class="mypage-form-input" style=" width: 100%; border: none; margin-top: 5px">
                                        <input type="email" class="form-control" style="margin: 0;" placeholder="sesmedia@yurulica.com" v-model="mailAddress">
                                    </label>
                                </div>
                                <div>
                                    <button type="submit" class="btn-md full-width pop-login form-button" :disabled="!mailAddress">アカウント削除</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "MyPageContents",
        props: {
            userData: {
                type: Object,
                default: () => {}
            },
            validationErrors: {
                type: Object,
                default: () => {}
            }
        },
        data: function() {
            return {
                mailAddress: ""
            }
        },
        computed: {
            companyData: {
                get() {
                    if (!this.userData || !this.userData.userGroup) {
                        return null;
                    }
                    return this.userData.userGroup.company;
                }
            },
            hasValidationError: {
                get() {
                    return Object.values(this.validationErrors).length > 0;
                }
            },
            allValidationError: {
                get() {
                    let allErrors = [];
                    for (const errors of Object.values(this.validationErrors)) {
                        allErrors = allErrors.concat(errors);
                    }
                    return allErrors;
                }
            },
          isOwner: {
            get() {
              if (!this.userData || !this.userData.userGroup) {
                return false;
              }
              return this.userData.id.toString() === this.userData.userGroup.ownerId.toString();
            }
          }
        },
        methods: {
            async logout () {
                this.$emit("logout");
            },
            async deleteAccount () {
                this.$emit("delete-account", this.mailAddress);
            },
            //PDF Download
            openPage() {
              window.open('assets/img/user_guide.pdf', '_blank')
            }

        }
    }
</script>

<style scoped>

</style>
