import {get, post} from "./api";
import Vue from 'vue'

const ApiMixIn = {
    data: function() {
        return {
            isLoading: false,
            validationErrors: {},
        }
    },
    computed: {
        hasValidationError: {
            get() {
                return Object.values(this.validationErrors).length > 0;
            }
        },
        allValidationError: {
            get() {
                let allErrors = [];
                for (const errors of Object.values(this.validationErrors)) {
                    allErrors = allErrors.concat(errors);
                }
                return allErrors;
            }
        }
    },
    methods: {
        async postApi(path, body, keepLoading, isResponseValidationErrors) {
            try {
                this.isLoading = true;
                this.validationErrors = {};
                const response = await post(path, body);
                if (!keepLoading) {
                    this.isLoading = false;
                }
                return response;
            } catch(e) {
                if (e.response && e.response.data) {
                    if (e.response.data.errorType === "NotLoginError") {
                        this.$router.push({ name: "Login" });
                    } else if (e.response.data.validationErrors) {
                        this.isLoading = false;
                        if (isResponseValidationErrors) {
                            return { validationErrors: e.response.data.validationErrors };
                        } else {
                            this.validationErrors = e.response.data.validationErrors;
                        }
                    } else {
                        this.pushError(e);
                    }
                } else {
                    this.pushError(e);
                }
            }
        },
        async getApi(path, params, keepLoading, isResponseValidationErrors) {
            try {
                this.isLoading = true;
                this.validationErrors = {};
                const response = await get(path, params);
                if (!keepLoading) {
                    this.isLoading = false;
                }
                return response;
            } catch(e) {
                if (e.response && e.response.data) {
                    if (e.response.data.errorType === "NotLoginError") {
                        this.$router.push({ name: "Login" });
                    } else if (e.response.data.validationErrors) {
                        this.isLoading = false;
                        if (isResponseValidationErrors) {
                            return { validationErrors: e.response.data.validationErrors };
                        } else {
                            this.validationErrors = e.response.data.validationErrors;
                        }
                    } else {
                        this.pushError(e);
                    }
                } else {
                    this.pushError(e);
                }
            }
        },
        pushError(e) {
            let errorMessage = "";
            let errorCode = 400;
            if (typeof e === "string") {
                errorMessage = e;
            } else {
                if (!e.response || !e.response.data || !e.response.data.errorText) {
                    errorMessage = "通信エラーが発生しました。\nネットワークの接続状況を確認して下さい。";
                } else {
                    errorCode = e.response.status;
                    errorMessage = e.response.data.errorText;
                }
            }
            Vue.prototype.$error = { title: "エラーが発生しました。", message: errorMessage, errorCode: errorCode }
            this.$router.push({ path: `${this.$router.currentRoute.path}/error` });
        },
        pushResult(title, message) {
            Vue.prototype.$result = { title: title, message: message }
            this.$router.push({ path: `${this.$router.currentRoute.path}/result` });
        }
    }
}

export default ApiMixIn;
