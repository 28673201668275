<template>
    <div class="modal fade" id="job-info-detail" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered login-pop-form" role="document">
            <div class="modal-content modal-frame job-modal-frame">
                <template v-if="selectedJobInfo">
                    <div class="modal-header">
                        <h4 style="font-size: 18px;">求人詳細</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="ti-close"></i></span></button>
                    </div>
                    <div class="modal-body job-modal-body">
                        <ul class="no-ul-list filter-list">
                            <li style="display: inline-flex;"><p class="job-form-label-position">{{getJobCategoryText(selectedJobInfo.jobInfo.jobCategory)}}</p></li>
                            <li class="job-form-title">{{selectedJobInfo.jobInfo.title}}</li>
                            <li class="job-form-company">企業名：{{selectedJobInfo.company.name}}</li>
                            <li class="job-form-digest"><p style="font-weight: 600; margin-bottom: -20px">業務内容</p><br>{{selectedJobInfo.jobInfo.jobDetail}}</li>
                            <li class="job-list-frame"><p class="job-form-label">職種</p><p class="job-form-main-listtext">{{getJobCategoryText(selectedJobInfo.jobInfo.jobCategory)}}</p></li>
                            <li v-if="selectedJobInfo.jobInfo.jobFeatures.length > 0" class="job-list-frame"><p class="job-form-label">求人の特徴</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.jobFeatures.map((jobFeature) => getJobFeatureText(jobFeature)).join(", ")}}</p></li>
                            <li class="job-list-frame"><p class="job-form-label">必須スキル</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.requiredSkill}}</p></li>
                            <li v-if="selectedJobInfo.jobInfo.recommendedSkill" class="job-list-frame"><p class="job-form-label">推奨スキル</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.recommendedSkill}}</p></li>
                            <li class="job-list-frame"><p class="job-form-label">勤務地</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.workLocation}}</p></li>
                            <li class="job-list-frame"><p class="job-form-label">最寄駅</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.nearestStation}}</p></li>
                            <li class="job-list-frame"><p class="job-form-label">想定給与</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.estimatedSalary}}</p></li>
                            <li v-if="selectedJobInfo.jobInfo.bonus" class="job-list-frame"><p class="job-form-label">賞与</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.bonus}}</p></li>
                            <li v-if="selectedJobInfo.jobInfo.evaluationSystem" class="job-list-frame"><p class="job-form-label">評価・昇給</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.evaluationSystem}}</p></li>
                            <li class="job-list-frame"><p class="job-form-label">週休制</p><p class="job-form-main-listtext">{{getWeeklyHolidaySystemText(selectedJobInfo.jobInfo.weeklyHolidaySystem)}}</p></li>
                            <li class="job-list-frame"><p class="job-form-label">雇用形態</p><p class="job-form-main-listtext">{{getEmploymentStatusText(selectedJobInfo.jobInfo.employmentStatus)}}</p></li>
                            <li v-if="selectedJobInfo.jobInfo.vacations.length > 0" class="job-list-frame"><p class="job-form-label">休暇</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.vacations.map((vacation) => getVacationText(vacation)).join(", ")}}</p></li>
                            <li v-if="selectedJobInfo.jobInfo.welfareSystems" class="job-list-frame"><p class="job-form-label">福利厚生</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.welfareSystems}}</p></li>
                            <li class="job-list-frame"><p class="job-form-label">勤務時間</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.workingStartTime}} 〜 {{selectedJobInfo.jobInfo.workingEndTime}}</p></li>
                            <li class="job-list-frame"><p class="job-form-label">業界歴</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.industryHistory}}年</p></li>
                            <li v-if="selectedJobInfo.jobInfo.desiredCandidateProfile" class="job-list-frame"><p class="job-form-label">求める人物像</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.desiredCandidateProfile}}</p></li>
                            <li v-if="selectedJobInfo.jobInfo.remarks" class="job-list-frame"><p class="job-form-label">備考</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.remarks}}</p></li>
                            <template v-if="isSalesType(selectedJobInfo.jobInfo.jobCategory) && selectedJobInfo.jobInfo.salesJobInfo">
                                <li v-if="selectedJobInfo.jobInfo.salesJobInfo.salesStyles.length > 0" class="job-list-frame"><p class="job-form-label">営業スタイル</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.salesJobInfo.salesStyles.map((salesStyle) => getSalesStyleText(salesStyle)).join(", ")}}</p></li>
                                <li class="job-list-frame"><p class="job-form-label">案件 or 人材</p><p class="job-form-main-listtext">{{getHandledSalesText(selectedJobInfo.jobInfo.salesJobInfo.handledSales)}}</p></li>
                                <li class="job-list-frame"><p class="job-form-label">役職</p><p class="job-form-main-listtext">{{getSalesJobPositionText(selectedJobInfo.jobInfo.salesJobInfo.salesJobPosition)}}</p></li>
                                <li v-if="selectedJobInfo.jobInfo.salesJobInfo.strengthIndustries.length > 0" class="job-list-frame"><p class="job-form-label">得意業界</p><p class="job-form-main-listtext">{{selectedJobInfo.jobInfo.salesJobInfo.strengthIndustries.map((strengthIndustry) => getStrengthIndustryText(strengthIndustry)).join(", ")}}</p></li>
                            </template>
                            <template v-else-if="selectedJobInfo.jobInfo.engineerJobInfo">
                                <li class="job-list-frame"><p class="job-form-label">技術領域</p><p class="job-form-main-listtext">{{getTechnicalFieldText(selectedJobInfo.jobInfo.engineerJobInfo.technicalField)}}</p></li>
                                <li class="job-list-frame"><p class="job-form-label">役職</p><p class="job-form-main-listtext">{{getSalesJobPositionText(selectedJobInfo.jobInfo.engineerJobInfo.engineerJobPosition)}}</p></li>
                            </template>
                        </ul>
<!--                        <a v-if="isShowJobContact" class="btn-md full-width pop-login mypage-menu weakbtn orange-btn" style="color: #ffffff; width: 100%" @click="onClickJobContact">応募フォームへ</a>-->
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>


<script>

    import {
        getEmploymentStatusText,
        getEngineerJobPositionText,
        getHandledSalesText,
        getJobCategoryText,
        getJobFeatureText,
        getSalesJobPositionText,
        getSalesStyleText,
        getStrengthIndustryText,
        getTechnicalFieldText,
        getVacationText,
        getWeeklyHolidaySystemText,
        JobCategory
    } from "@/common/enum";

    export default {
        name: "JobInfoDetailModal",
        props: {
            selectedJobInfo: {
                type: Object,
                default: () => {}
            }
        },
        methods: {
            getJobCategoryText (jobCategoryType) {
                return getJobCategoryText(jobCategoryType);
            },
            getJobFeatureText (jobFeatureType) {
                return getJobFeatureText(jobFeatureType);
            },
            getWeeklyHolidaySystemText: function (weeklyHolidaySystemType) {
                return getWeeklyHolidaySystemText(weeklyHolidaySystemType);
            },
            getEmploymentStatusText: function (employmentStatusType) {
                return getEmploymentStatusText(employmentStatusType);
            },
            getVacationText: function (vacationType) {
                return getVacationText(vacationType);
            },
            getSalesStyleText: function (salesStyleType) {
                return getSalesStyleText(salesStyleType);
            },
            getHandledSalesText: function (handledSalesType) {
                return getHandledSalesText(handledSalesType);
            },
            getSalesJobPositionText: function (salesJobPositionType) {
                return getSalesJobPositionText(salesJobPositionType);
            },
            getStrengthIndustryText: function (vacationType) {
                return getStrengthIndustryText(vacationType);
            },
            getTechnicalFieldText: function (technicalFieldType) {
                return getTechnicalFieldText(technicalFieldType);
            },
            getEngineerJobPositionText: function (engineerJobPositionType) {
                return getEngineerJobPositionText(engineerJobPositionType);
            },
            isSalesType(jobCategory) {
                return jobCategory === JobCategory.Sales;
            },
            onClickJobContact() {
                // eslint-disable-next-line no-undef
                $('#job-info-detail').modal("hide");
                this.$router.push({ name: 'JobContactForm', query: { id: this.selectedJobInfo.jobInfo.id } });
            }
        }
    }
</script>

<style scoped>

</style>
