
export const JobCategory = {
    Engineer: 0,
    Sales: 1
}

export const JobFeature = {
    RemoteWorkOK: 0, // リモートワークOK
    FullTwoDaysOff: 1, // 完全週休二日制
    WeekendsAndHolidaysOff: 2, // 土日祝休み
    ShiftSystem: 3, // シフト制
    Over120Holidays: 4, // 休日120日以上
    HasCertificationAllowance: 5, // 資格手当あり
    EducationNotRequired: 6, // 学歴不問
    NoExperienceRequired: 7, // 未経験OK
    ExperiencePreferred: 8, // 経験者歓迎
    HasSocialInsurance: 9, // 社保完備
    HasRetirementBenefits: 10, // 退職金あり
    ProvidesTraining: 11, // 研修あり
    CasualDressCode: 12 // 服装自由
}

export const WeeklyHolidaySystem = {
    FullTwoDaysOff: 0,
    FullThreeDaysOff: 1,
    TwoDaysOff: 2,
    WeeklyOffSystem: 3,
    Others: 4
}

export const Vacation = {
    HolidayOff: 0,
    YearEndNewYearVacation: 1,
    GoldenWeekVacation: 2,
    SummerVacation: 3,
    SpecialEventLeave: 4,
    PaidLeave: 5,
    ParentalLeave: 6,
    CareLeave: 7
}

export const EmploymentStatus = {
    RegularEmployee: 0,
    ContractEmployee: 1,
    TemporaryWorker: 2,
    PartTimeWorker: 3,
    Contractor: 4
}

export const SalesStyle = {
    DirectHireSales: 0,
    PartnerSales: 1,
    FreelanceSales: 2
}

export const HandledSales = {
    Project: 0,
    Personnel: 1
}

export const SalesJobPosition = {
    Members: 0,
    Representative: 1,
    Manager: 2,
    Assistant: 3
}

export const StrengthIndustry = {
    BusinessSystem: 0,
    Web: 1,
    Game_Entertainment: 2
}

export const TechnicalField = {
    FrontEnd: 0,
    ServerSide: 1,
    System: 2,
    Infrastructure: 3,
    Network: 4,
    GameClient: 5,
    QA: 6,
    WebCoder: 7
}

export const EngineerJobPosition = {
    Members: 0,
    TechLead: 1,
    CTO: 2
}


export function getJobCategoryText(jobCategoryType) {
    switch (jobCategoryType) {
        case JobCategory.Engineer:
            return "エンジニア"
        case JobCategory.Sales:
            return "営業"
    }
    return "";
}

export function getJobFeatureText(jobFeatureType) {
    switch (jobFeatureType) {
        case JobFeature.RemoteWorkOK:
            return "リモートワークOK"
        case JobFeature.FullTwoDaysOff:
            return "完全週休二日制"
        case JobFeature.WeekendsAndHolidaysOff:
            return "シフト制"
        case JobFeature.ShiftSystem:
            return "完全週休二日制"
        case JobFeature.Over120Holidays:
            return "休日120日以上"
        case JobFeature.HasCertificationAllowance:
            return "資格手当あり"
        case JobFeature.EducationNotRequired:
            return "学歴不問"
        case JobFeature.NoExperienceRequired:
            return "未経験OK"
        case JobFeature.ExperiencePreferred:
            return "経験者歓迎"
        case JobFeature.HasSocialInsurance:
            return "社保完備"
        case JobFeature.HasRetirementBenefits:
            return "退職金あり"
        case JobFeature.ProvidesTraining:
            return "研修あり"
        case JobFeature.CasualDressCode:
            return "服装自由"
    }
    return "";
}

export function getWeeklyHolidaySystemText(weeklyHolidaySystemType) {
    switch (weeklyHolidaySystemType) {
        case WeeklyHolidaySystem.FullTwoDaysOff:
            return "完全週休2日制"
        case WeeklyHolidaySystem.FullThreeDaysOff:
            return "完全週休3日制"
        case WeeklyHolidaySystem.TwoDaysOff:
            return "週休2日制"
        case WeeklyHolidaySystem.WeeklyOffSystem:
            return "週休制"
        case WeeklyHolidaySystem.Others:
            return "その他"
    }
    return "";
}

export function getEmploymentStatusText(employmentStatusType) {
    switch (employmentStatusType) {
        case EmploymentStatus.RegularEmployee:
            return "正社員"
        case EmploymentStatus.ContractEmployee:
            return "契約社員"
        case EmploymentStatus.TemporaryWorker:
            return "派遣"
        case EmploymentStatus.PartTimeWorker:
            return "パート"
        case EmploymentStatus.Contractor:
            return "業務委託"
    }
    return "";
}

export function getVacationText(vacationType) {
    switch (vacationType) {
        case Vacation.HolidayOff:
            return "祝日休み"
        case Vacation.YearEndNewYearVacation:
            return "年末年始休暇"
        case Vacation.GoldenWeekVacation:
            return "GW休暇"
        case Vacation.SummerVacation:
            return "夏季休暇"
        case Vacation.SpecialEventLeave:
            return "慶弔休暇"
        case Vacation.PaidLeave:
            return "有給休暇"
        case Vacation.ParentalLeave:
            return "育児休暇"
        case Vacation.CareLeave:
            return "介護休暇"
    }
    return "";
}


export function getSalesStyleText(salesStyleType) {
    switch (salesStyleType) {
        case SalesStyle.DirectHireSales:
            return "プロパー中心"
        case SalesStyle.PartnerSales:
            return "パートナー中心"
        case SalesStyle.FreelanceSales:
            return "フリーランス中心"
    }
    return "";
}

export function getHandledSalesText(handledSalesType) {
    switch (handledSalesType) {
        case HandledSales.Project:
            return "案件"
        case HandledSales.Personnel:
            return "人材"
    }
    return "";
}

export function getSalesJobPositionText(salesJobPositionType) {
    switch (salesJobPositionType) {
        case SalesJobPosition.Members:
            return "メンバー"
        case SalesJobPosition.Representative:
            return "代表"
        case SalesJobPosition.Manager:
            return "部長・マネージャー"
        case SalesJobPosition.Assistant:
            return "アシスタント"
    }
    return "";
}

export function getStrengthIndustryText(strengthIndustryType) {
    switch (strengthIndustryType) {
        case StrengthIndustry.BusinessSystem:
            return "業務系"
        case StrengthIndustry.Web:
            return "Web系"
        case StrengthIndustry.Game_Entertainment:
            return "ゲーム・エンタメ系"
    }
    return "";
}

export function getTechnicalFieldText(technicalFieldType) {
    switch (technicalFieldType) {
        case TechnicalField.FrontEnd:
            return "フロントエンドエンジニア"
        case TechnicalField.ServerSide:
            return "サーバーサイドエンジニア"
        case TechnicalField.System:
            return "システムエンジニア"
        case TechnicalField.Infrastructure:
            return "インフラエンジニア"
        case TechnicalField.Network:
            return "ネットワークエンジニア"
        case TechnicalField.GameClient:
            return "ゲームクライアントエンジニア"
        case TechnicalField.QA:
            return "QAエンジニア"
        case TechnicalField.WebCoder:
            return "Webコーダー"
    }
    return "";
}

export function getEngineerJobPositionText(engineerJobPositionType) {
    switch (engineerJobPositionType) {
        case EngineerJobPosition.Members:
            return "メンバー"
        case EngineerJobPosition.TechLead:
            return "テックリード"
        case EngineerJobPosition.CTO:
            return "CTO"
    }
    return "";
}


export const DesiredJobChangeDate = {
    Immediate: 0,
    WithinThreeMonths: 1,
    WithinSixMonths: 2,
    WithinOneYear: 3
}
export function getDesiredJobChangeDateText(desiredJobChangeDate) {
    switch (desiredJobChangeDate) {
        case DesiredJobChangeDate.Immediate:
            return "すぐにでも";
        case DesiredJobChangeDate.WithinThreeMonths:
            return "3ヶ月以内転職希望";
        case DesiredJobChangeDate.WithinSixMonths:
            return "6ヶ月以内に転職希望";
        case DesiredJobChangeDate.WithinOneYear:
            return "1年以内に転職希望";
    }
}